import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import styles from './home.module.scss'
import BlogLayout from 'layouts/Blog'
import SEO from 'components/shared/SEO'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <BlogLayout location={this.props.location} title={siteTitle}>
        <SEO />
        <div className={styles.container}>
          <div className={styles.posts}>
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                <Link to={`/blog${node.fields.slug}`}>
                  <div
                    key={node.fields.slug}
                    className={styles.blogPostPreview}
                  >
                    {node.frontmatter.featuredImage && (
                      <Img
                        sizes={
                          node.frontmatter.featuredImage.childImageSharp.sizes
                        }
                        className={styles.featuredImage}
                      />
                    )}
                    <div className={styles.blogPostPreviewContent}>
                      <h3 className={styles.postTitle}>{title}</h3>
                      <small className={styles.date}>
                        {node.frontmatter.date}
                      </small>
                      <p
                        className={styles.blogPostPreviewDescription}
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </BlogLayout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
